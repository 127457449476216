/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandiweb/StoreFinder/Util/Helper/sortBySortOrder */
export const sortBySortOrder = (options, sortKey = 'sort_order') => options.sort(
    (a, b) => {
        if (a[sortKey] < b[sortKey]) {
            return -1;
        }

        if (a[sortKey] > b[sortKey]) {
            return 1;
        }

        return 0;
    }
);

/** @namespace Scandiweb/StoreFinder/Util/Helper/handleOnBackClick */
export const handleOnBackClick = () => {
    const { state } = history;
    if (!state) {
        history.pushState(appendWithStoreCode('/'));
    } else {
        history.back();
    }
};
