/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { Field, prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import StoreFinderQuery from '../../query/StoreFinder.query';
import { setIsLoading, updateApiKey, updateStoreFinder } from './StoreFinder.action';

/** @namespace Scandiweb/StoreFinder/Store/StoreFinder/Dispatcher */
export class StoreFinderDispatcher {
    async requestStoreData(dispatch) {
        try {
            dispatch(setIsLoading(true));

            const rawQueries = [StoreFinderQuery.getQuery()];
            const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;
            const { getStores } = await executeGet(prepareQuery(queries, true));

            dispatch(updateStoreFinder(getStores));

            return true;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('>>> ERROR:', e);
            dispatch(showNotification('error', e));
        } finally {
            dispatch(setIsLoading(false));
        }

        return false;
    }

    async requestAPIKey(dispatch) {
        try {
            dispatch(setIsLoading(true));

            const rawQueries = StoreFinderQuery.getStoreFinderConfig();
            const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;
            const {
                getStoreFinderConfig: {
                    google_api_key
                }
            } = await executeGet(prepareQuery(queries, true));

            dispatch(updateApiKey(google_api_key));

            return google_api_key;
        } catch (e) {
            dispatch(showNotification('error', e));
        } finally {
            dispatch(setIsLoading(false));
        }

        return false;
    }
}

export default new StoreFinderDispatcher();
