/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/StoreFinder/Query/StoreFinder/Query */
export class StoreFinderQuery {
    /**
     * get Stores query
     * @return {Query} Store finder query
     * @memberof StoreFinderQuery
     */
    getQuery() {
        return new Field('getStores')
            .addFieldList([
                'store_name',
                'address',
                'city',
                'phone_number',
                'latitude',
                'longitude',
                'store_hours',
                'working_days',
                'image',
                'description',
                'identifier',
                'has_store_page',
                'store_email',
                'position'
            ]);
    }

    getStoreFinderConfig() {
        return new Field('getStoreFinderConfig')
            .addField('google_api_key');
    }
}

export default new (StoreFinderQuery)();
