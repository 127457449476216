/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const LocationType = PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string
});

export const LocationsType = PropTypes.arrayOf(LocationType);

export const StoreType = PropTypes.shape({
    active: PropTypes.bool,
    address: PropTypes.string,
    city: PropTypes.string,
    description: PropTypes.string,
    has_store_page: PropTypes.bool,
    identifier: PropTypes.string,
    image: PropTypes.arrayOf(PropTypes.string),
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    phone_number: PropTypes.string,
    position: PropTypes.string,
    store_email: PropTypes.string,
    store_hours: PropTypes.string,
    store_name: PropTypes.string,
    working_days: PropTypes.string
});

export const MetaObjectType = PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    meta_title: PropTypes.string,
    meta_description: PropTypes.string,
    meta_keyword: PropTypes.string
});
