/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_STORE_FINDER = 'UPDATE_STORE_FINDER';
export const UPDATE_IS_STORE_FINDER_LOADING = 'UPDATE_IS_STORE_FINDER_LOADING';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';

/**
 * @namespace Scandiweb/StoreFinder/Store/StoreFinder/Action/updateStoreFinder */
export const updateStoreFinder = (value) => ({
    type: UPDATE_STORE_FINDER,
    value
});

/** @namespace Scandiweb/StoreFinder/Store/StoreFinder/Action/setIsLoading */
export const setIsLoading = (value) => ({
    type: UPDATE_IS_STORE_FINDER_LOADING,
    value
});

/** @namespace Scandiweb/StoreFinder/Store/StoreFinder/Action/updateApiKey */
export const updateApiKey = (value) => ({
    type: UPDATE_API_KEY,
    value
});
